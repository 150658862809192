import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';

import './style.css';

import $ from 'jquery';

import BpmnModeler from 'bpmn-js/lib/Modeler';

import diagramXML from '../resources/newDiagram.bpmn';


var container = $('#js-drop-zone');



var modeler = new BpmnModeler({
  container: '#js-canvas',
  keyboard: {
    bindTo: window
  }
});

modeler.on('element.changed', async() => {
 const {xml} = await modeler.saveXML({ format: true });
 $.ajax({
  type: "POST",
  url: "https://apipluto.projectzerozilla.com/v1/api/data/save-xml",
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer "
  },
  data: JSON.stringify({xml}),
  success: function (data) {
    console.log(data);
  }
});
})
modeler.on('element.click', () => {
  const cmc = document.querySelectorAll('.context-menu')
  Array.from(cmc).forEach((el) => {
    el.remove();
  })
})
modeler.on('drag.start', () => {
  removeAllClipContent();
})
modeler.on('element.mousemove', () => {
  removeAllClipContent();
})
modeler.on('element.contextmenu', (e) => {
  console.log(e)
  if (e.element.id != "Process_1") {
    e.preventDefault()
    // Initialize and configure a new menu
    removeAllClipContent();
    e.gfx.classList.add(e.element.id)
    const id = e.element.id
    const div = document.createElement('div');
    div.innerHTML = `<div class="card position-absolute context-menu" style="box-shadow: .3em .3em .5em gray;left: ${e.originalEvent.x}px; top: ${e.originalEvent.y}px;">
    <ul class="list-group list-group-flush">
      <li class="list-group-item list-group-item-action event-config addPin">Add Pin</li>
      <li class="list-group-item list-group-item-action event-config addDetail">Add Detail</li>
    </ul>
  </div>`
    document.body.appendChild(div)
    const pins = document.querySelectorAll('.addPin');
    Array.from(pins).forEach((node) => {
      node.addEventListener('click', (evt) => {
        removeAllClipContent();
        const newpathg = document.createElementNS("http://www.w3.org/2000/svg", "g");
        newpathg.setAttributeNS(null, "data-clip", e.element.id)
        newpathg.setAttributeNS(null, "transform", `matrix(1 0 0 1 ${e.element.width - 5} -15)`);
        const newpath = document.createElementNS("http://www.w3.org/2000/svg", "path");
        newpath.setAttributeNS(null, "d", "M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z");
        newpath.setAttributeNS(null, "stroke", "red");
        newpath.setAttributeNS(null, "stroke-width", 1);
        newpath.setAttributeNS(null, "opacity", 1);
        newpath.setAttributeNS(null, "fill", "red");
        newpath.setAttributeNS(null, "class", "event-config");
        newpath.setAttributeNS(null, "x", "500");
        newpath.setAttributeNS(null, "y", "500");
        newpath.setAttributeNS(null, "transform", `scale(0.05)`);
        newpath.addEventListener('click', (ee) => {
          createClipDetailsContaint(e.element.id, ee.x, ee.y)
        })
        newpathg.appendChild(newpath)
        document.querySelector('.' + e.element.id).appendChild(newpathg);
        $.ajax({
          type: "POST",
          url: "https://apipluto.projectzerozilla.com/v1/api/data/save-shape-details",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer "
          },
          data: JSON.stringify({
            shapeId:e.element.id,
            attachmentIcon:true,
            shapeTitle:null,
            shapeHeaderProperties:null,
            shapeFooterProperties:null}),
          success: function (data) {
            console.log(data);
          }
        });
      })
    })
    const detail = document.querySelectorAll('.addDetail');
    Array.from(detail).forEach((node) => {
      node.addEventListener('click', (evt) => {
        removeAllClipContent()
        const myModalAlternative = new bootstrap.Modal('#detailsModal')
        myModalAlternative.show()
      })
    })
  }
})
function createNewDiagram() {
  openDiagram(diagramXML);
}

async function openDiagram(xml) {

  try {
    // await modeler.importXML(xml);
$.ajax({
  type: "GET",
  url: "https://apipluto.projectzerozilla.com/v1/api/data/get-xml",
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer "  
  },
  success: async function (data) {
    console.log(data);
    await modeler.importXML(data.data?.xml);

  }
});
$.ajax({
  type: "GET",
  url: "https://apipluto.projectzerozilla.com/v1/api/data/get-shape-details",
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer "
  },
  success: async function (data) {
    console.log(data)
    const groupNode = document.querySelectorAll('g');
    Array.from(groupNode).forEach((node) => {
      data.data?.forEach((shape) => {
      if(node.dataset.elementId == shape?.shapeId) {
        node.classList.add(node.dataset.elementId);
        console.log(node.getBBox().width)
        const newpathg = document.createElementNS("http://www.w3.org/2000/svg", "g");
        newpathg.setAttributeNS(null, "data-clip", node.dataset.elementId)
        newpathg.setAttributeNS(null, "transform", `matrix(1 0 0 1 ${node.getBBox().width - 15} -15)`);
        const newpath = document.createElementNS("http://www.w3.org/2000/svg", "path");
        newpath.setAttributeNS(null, "d", "M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z");
        newpath.setAttributeNS(null, "stroke", "red");
        newpath.setAttributeNS(null, "stroke-width", 1);
        newpath.setAttributeNS(null, "opacity", 1);
        newpath.setAttributeNS(null, "fill", "red");
        newpath.setAttributeNS(null, "class", "event-config");
        newpath.setAttributeNS(null, "x", "500");
        newpath.setAttributeNS(null, "y", "500");
        newpath.setAttributeNS(null, "transform", `scale(0.05)`);
        newpath.addEventListener('click', (ee) => {
          console.log(node.dataset.elementId)
          createClipDetailsContaint(node.dataset.elementId, ee.x, ee.y)
        })  
        newpathg.appendChild(newpath)
        node.appendChild(newpathg)
      }
    })
  })
}
});
    container
      .removeClass('with-error')
      .addClass('with-diagram');
  } catch (err) {

    container
      .removeClass('with-diagram')
      .addClass('with-error');

    container.find('.error pre').text(err.message);

    console.error(err);
  }
}

function registerFileDrop(container, callback) {

  function handleFileSelect(e) {
    e.stopPropagation();
    e.preventDefault();

    var files = e.dataTransfer.files;

    var file = files[0];

    var reader = new FileReader();

    reader.onload = function (e) {

      var xml = e.target.result;

      callback(xml);
    };

    reader.readAsText(file);
  }

  function handleDragOver(e) {
    e.stopPropagation();
    e.preventDefault();

    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
  }

  container.get(0).addEventListener('dragover', handleDragOver, false);
  container.get(0).addEventListener('drop', handleFileSelect, false);
}


// file drag / drop ///////////////////////

// check file api availability
if (!window.FileList || !window.FileReader) {
  window.alert(
    'Looks like you use an older browser that does not support drag and drop. ' +
    'Try using Chrome, Firefox or the Internet Explorer > 10.');
} else {
  registerFileDrop(container, openDiagram);
}

// bootstrap diagram functions

$(function () {

  $('#js-create-diagram').click(function (e) {
    e.stopPropagation();
    e.preventDefault();

    createNewDiagram();
  });

  var downloadLink = $('#js-download-diagram');
  var downloadSvgLink = $('#js-download-svg');

  $('.buttons a').click(function (e) {
    if (!$(this).is('.active')) {
      e.preventDefault();
      e.stopPropagation();
    }
  });

  function setEncoded(link, name, data) {
    var encodedData = encodeURIComponent(data);

    if (data) {
      link.addClass('active').attr({
        'href': 'data:application/bpmn20-xml;charset=UTF-8,' + encodedData,
        'download': name
      });
    } else {
      link.removeClass('active');
    }
  }

  var exportArtifacts = debounce(async function () {

    try {

      const { svg } = await modeler.saveSVG();

      setEncoded(downloadSvgLink, 'diagram.svg', svg);
    } catch (err) {

      console.error('Error happened saving svg: ', err);
      setEncoded(downloadSvgLink, 'diagram.svg', null);
    }

    try {

      const { xml } = await modeler.saveXML({ format: true });
      setEncoded(downloadLink, 'diagram.bpmn', xml);
    } catch (err) {

      console.error('Error happened saving XML: ', err);
      setEncoded(downloadLink, 'diagram.bpmn', null);
    }
  }, 500);

  modeler.on('commandStack.changed', exportArtifacts);
});



// helpers //////////////////////

function debounce(fn, timeout) {

  var timer;

  return function () {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(fn, timeout);
  };
}

createNewDiagram()
function removeAllClipContent() {
  const ccc = document.querySelectorAll('.clipContent')
  Array.from(ccc).forEach((el) => {
    el.remove();
  })
  const cmc = document.querySelectorAll('.context-menu')
  Array.from(cmc).forEach((el) => {
    el.remove();
  })
}
function createClipDetailsContaint(id, left, top) {
  removeAllClipContent()
  let listContent = ''
  for (const element of [1, 2, 3, 4, 5, 6, 7]) {
    listContent += ` <li class="list-group-item">
        <div class="d-flex">
          <div class="me-auto">An item</div>
          <svg xmlns="http://www.w3.org/2000/svg" class="event-config" height="20px" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#15fa05" d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM216 232l0 102.1 31-31c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31L168 232c0-13.3 10.7-24 24-24s24 10.7 24 24z"/></svg>
        </div>
      </li>`
  }
  const div = document.createElement('div');
  div.innerHTML = `<div class="card position-absolute clipContent" style="width: 250px;left:${left}px;top:${top}px;box-shadow: .3em .3em .5em gray;">
    <div class="card-header">
      <div class="d-flex">
        <div class="me-auto">Text Content</div>
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" class="position-absolute event-config closePinContent" style="width: 250px;left:${125}px;top:${-10}px" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path fill="#fb0909" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
          </svg>
        </div>
      </div>
    <ul class="list-group">
     ${listContent}
    </ul>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <button type="button" role="button" data-managepinid="${id}" class="managePin btn btn-sm btn-warning">Manage Links</button>
        <button type="button" role="button" data-deletepinid="${id}" class="deletePin btn btn-sm btn-secondary">Delete Pin</button>
      </div>
    </div>
  </div>`
  document.body.appendChild(div)
  const clipContentClose = document.querySelectorAll('.closePinContent');
  Array.from(clipContentClose).forEach((node) => {
    node.addEventListener('click', (e) => {
      removeAllClipContent();
    })
  })
  const pins = document.querySelectorAll('.deletePin');
  Array.from(pins).forEach((node) => {
    node.addEventListener('click', (e) => {
      console.log(e);
      const shapes = document.querySelectorAll('.' + e.target.dataset.deletepinid);
      Array.from(shapes).forEach((parent) => {
        parent.childNodes.forEach((child) => {
          if (child.dataset.clip == e.target.dataset.deletepinid) {
            child.remove();
            removeAllClipContent();
            $.ajax({
              type: "DELETE",
              url: "https://apipluto.projectzerozilla.com/v1/api/data/delete-shape-details/"+e.target.dataset.deletepinid,
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "
              },
              success: function (data) {
                console.log(data);
              }
            });
          }
        })
      })
    })
  })
  const manage = document.querySelectorAll('.managePin');
  Array.from(manage).forEach((node) => {
    node.addEventListener('click', (e) => {
      console.log(e.target.dataset.managepinid);
      const myModalAlternative = new bootstrap.Modal('#managePinModal')
      myModalAlternative.show()
      removeAllClipContent();
    })
  })
}


